<template>
  <div class="mobile-company-nav">
    <div class="mobile-company-nav__name">
      {{ name }}
    </div>
    <ul class="mobile-company-nav__list">
      <li
        v-for="route in routes"
        :key="route.name"
        class="mobile-company-nav__list-item"
      >
        <router-link
          :to="route.link"
          :data-testid="route.testId"
          class="mobile-company-nav__list-item-link"
          @click.native.stop="closeMobileNav"
        >
          <i :class="['icon', `icon-${route.icon}`, 'mobile-company-nav__list-item-link-image']" />
          <p
            class="mobile-company-nav__list-item-link-text"
          >
            {{ $t(route.name ) }}
          </p>
        </router-link>
      </li>
      <li class="mobile-company-nav__list-item">
        <div
          data-testid="company-menu-logout"
          class="mobile-company-nav__list-item-link"
          @click="logout"
        >
          <i class="icon icon-logout mobile-company-nav__list-item-link-image" />
          <p class="mobile-company-nav__list-item-link-text">
            {{ $t('partner-portal.header.company-routes.logout') }}
          </p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CompanyNavMobile',

  inject: {
    closeMobileNav: {
      from: 'Header.CloseMobileNav',
      default: () => ({}),
    },
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    routes: {
      type: Array,
      required: true,
    },
    logout: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.mobile-company-nav {
  display: flex;
  flex-direction: column;
  background: $mobile-company-background;
  padding: 20px 16px;
  flex-grow: 1;

  &__name {
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 20px;
    color: $primary;
  }

  &__list {
    margin-top: 0;
    padding: 0;
  }

  &__list-item {
    padding: 12px 0;
    display: flex;

    &:first-child {
      padding-top: 0;
    }
  }

  &__list-item-link {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 15px;
    line-height: 18px;
    color: $primary;
    text-decoration: none;;
  }

  &__list-item-link-image {
    width: 16px;
    height: 16px;
  }

  &__list-item-link-text {
    margin: 0 0 0 13px;
  }
}

@media screen and (min-width: $tablet-breakpoint) {
  .mobile-company-nav {
    display: none;
  }
}
</style>
